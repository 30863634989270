import {
  Box,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { addSpace } from "../../../../utils/spaceSeprator";
import SquareIcon from "@mui/icons-material/Square";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiSlider-thumb": {
      background: "black",
      width: "4px",
      height: "10px",
      borderRadius: "0px",
    },
    "& .MuiSlider-rail": {
      background:
        "linear-gradient(90deg, rgba(255,109,112,1) 28%, rgba(255,241,240,1) 53%, rgba(0,255,70,1) 75%)",
      height: "14px", // Adjust this value as needed to increase the height
    },
  },
}));

const BenchMarkTable = ({ tableData }) => {
  const [value, setValue] = useState(50);
  const benchTable = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const headerList = tableData.map((e) => {
    return e?._id;
  });
  const metricList = Object.keys(tableData[0]).filter(
    (e) => !e.includes("Color") && e !== "_id" && !e.includes("Percentile")
  );

  const marks = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 25,
      label: "25%",
    },
    {
      value: 50,
      label: "50%",
    },
    {
      value: 75,
      label: "75%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];

  return (
    <div>
      {/* <Box width={"40%"} mx={"auto"} my={2}>
        <Slider
          aria-label="Volume"
          value={value}
          onChange={handleChange}
          track={false}
          color="secondary"
          className={benchTable.root}
          marks={marks}
          disabled
          size="medium"
        />
      </Box> */}

      <Box
        my={2}
        display={"flex"}
        justifyContent={"center"}
        className={"cursorPointer"}
      >
        <Tooltip
          title={
            <>
              <b>Green</b> : The team/contributor has a better value than the
              average.
            </>
          }
          placement="top-start"
          arrow
        >
          <SquareIcon sx={{ color: "#9effb8" }} /> <span>Green</span> &nbsp;
        </Tooltip>
        <Tooltip
          placement="top-start"
          arrow
          title={
            <>
              <b>Red</b> : The team/Contributor has less value than the average.
            </>
          }
        >
          <SquareIcon sx={{ color: "#ffc7c8" }} /> <span>Red</span>
        </Tooltip>
      </Box>

      <TableContainer>
        <Table sx={{ minWidth: 650, border: "0px" }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ background: "rgb(239, 239, 239)" }}>
              <TableCell>Metrics</TableCell>
              {headerList.map((item) => {
                return <TableCell align="center">{item}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {metricList.map((row, index) => {
              // if (index === 0) return <></>;
              return (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {addSpace(row)}
                  </TableCell>

                  {tableData.map((e) => (
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        backgroundColor: e[`${row}Color`] || "transparent",
                      }}
                    >
                      {e[row]}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default BenchMarkTable;
