import React, { useState, useEffect, useMemo } from "react";
import axios from "../../../utils/axiosHelper";
import { format } from "date-fns";
import InvestmentProfileTemplate from "../../investmentProfile/InvestmentProfileTemplate";
import { addSpace } from "../../../utils/spaceSeprator";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { GlobalData } from "../../../context/globalData";
import { useContext } from "react";
import { addLeadingZeroToDateRange, datePayload } from "../../../utils/moduleTabApi";
import { Card, Grid } from "@material-ui/core";
import check from "../../../assets/check.png";
import CardDetail from "../../../components/Header/cardDetail";
import { config } from "../../../Config/permission";
import { Box } from "@mui/material";
import InvestmentFilter from "../../../components/investmentProfileFilter/InvestmentFilter";

const TeamInvestmentProfile = ({ project, teamId, epic, teamName }) => {
  const { projectRangePickerValue } = useContext(GlobalData);
  const [issueType, setIssueType] = useState();
  const [issueList, setIssueList] = useState();
  const [date, setDate] = useState(projectRangePickerValue);
  const [pieGraphData, setpieGraphData] = useState();
  const [issueData, setIssueData] = useState();
  const [issueColumn, setIssueColumn] = useState();
  const [trendDate, setTrendDate] = useState(projectRangePickerValue);
  const [iteration, setIteration] = useState();
  const [stackChartData, setStackChartData] = useState();
  const [HeaderData, setHeaderData] = useState({});
  const [graphType, setGraphType] = useState("workday");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [issueCostHandler, setIssueCostHandler] = React.useState(null);
  const [selectedViewBy, setSelectedViewBy] = useState();
  const [epicData, setEpicData] = useState({ url: "", selectedEpic: [] });
  const [costAmt, setCostAmt] = useState("");
  const [issueCostAmt, setIssueCostAmt] = useState("");
  const [TrendUnit, setTrendUnit] = useState("Day's");
  const [TrendLabel, setTrendLabel] = useState("Time (Day's)");
  const [Cumulative, setCumulative] = useState("");
  const [issueLevelCost, setIssueLevelCost] = useState();
  const [totalCostInDoller, setTotalCostInDoller] = useState("");

  console.log(issueCostAmt, "issueCostAmt");

  const SprintDetailHandler = () => {
    if (!project || !teamId) return;
    const dateUrl = datePayload(date);
    let url = `/api/v2/investmentProfile/resourceLevelDetails?projectId=${project}&teamId=${teamId}&${dateUrl}`;
    if (viewType) {
      url += `&type=${viewType}`;
    }
    if (viewType === "Cost") {
      url += `&cost=${costAmt}`;
    }
    if (epicData.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((result) => {
        setHeaderData({
          TaskCount: result.data.TaskCount,
          StartDate: format(new Date(result?.data?.StartDate), "dd-MM-yyyy"),
          EndDate: format(new Date(result?.data?.EndDate), "dd-MM-yyyy"),
        });
        let issue = result?.data?.IssueType?.map((item) => item.IssueType);
        setIssueList(issue);
        let pieGraphData = result?.data?.res?.map((item) => {
          return {
            title: item?.title,
            value: item?.value,
          };
        });
        setpieGraphData(pieGraphData);
        setIssueType(result?.data?.IssueType?.[0]?.IssueType);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const viewType = useMemo(() => {
    const type = selectedViewBy?.includes("Cost") ? "Cost" : selectedViewBy;
    return type;
  }, [selectedViewBy]);

  const issueDetailsHandler = () => {
    const dateUrl = datePayload(date);
    if (!project || !teamId) return;
    let url = `/api/v2/investmentProfile/resourceLevelIssue/${project}?issuetype=${issueType}&${dateUrl}&type=${viewType}&teamName=${teamName}`;

    if (issueType === "All") {
      url = `/api/v2/investmentProfile/resourceLevelIssue/${project}?${dateUrl}&type=${viewType}&teamName=${teamName}`;
    }
    if (viewType === "Cost") {
      url += `&cost=${costAmt}`;
    }
    if (epicData.url) {
      url += epicData.url.replace("?", "&");
    }
    if (issueLevelCost) {
      url += `&cost=${issueLevelCost}`;
    }
    axios
      .get(url)
      .then((result) => {
        setIssueData(result?.data?.result2);
        setCumulative(result?.data?.totalFTE);
        const totalCost = result?.data?.result2.reduce((acc, curr) => {
          return acc + curr?.Cost;
        }, 0);
        setTotalCostInDoller(totalCost);
        const data = Object.keys(
          result.data.result2.length > 0 ? result?.data?.result2?.[0] : {}
        )?.filter((e) => {
          if (e !== "tableData" && e !== "TaskLink") {
            return e;
          }
        });
        const columns = data?.map((e) => {
          return {
            field: e,
            title: addSpace(e),
            render: (data) => {
              if (e === "TaskId") {
                return (
                  <a target="_blank" href={data["TaskLink"]} rel="noreferrer">
                    {data?.TaskId}
                  </a>
                );
              } else if (e === "CreatedTime") {
                return format(new Date(data[e]), "dd-MMM-yyy");
              } else {
                return data[e];
              }
            },
          };
        });
        setIssueColumn(columns);
        // window.scrollTo(0, document.body.scrollHeight);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const staclchartHandler = () => {
    if (!project) return;
    const dateUrl = datePayload(date);
    const zeroDate = addLeadingZeroToDateRange(dateUrl)
    let url = "";
    if (epicData?.url) {
      url = `/api/v2/investmentProfile/monthtrend/${project}?iteration=${iteration}&level=Resource&${zeroDate}&teamId=${teamId}&selectType=${graphType}${epicData?.url}`;
    } else {
      url = `/api/v2/investmentProfile/monthtrend/${project}?iteration=${iteration}&level=Resource&${zeroDate}&teamId=${teamId}&selectType=${graphType}`;
    }
    if (viewType) {
      url += `&type=${viewType}`;
    }
    if (viewType === "Cost") {
      url += `&cost=${costAmt}`;
    }
    axios
      .get(url)
      .then((result) => {
        // setStackChartData(result?.data);
        const response = result?.data;
        const emptySet = new Set([]);
        response?.filter((data) => {
          Object.keys(data)?.filter((key) => {
            if (!emptySet?.has(key)) {
              emptySet?.add(key);
            }
          });
        });
        Array.from(emptySet)?.filter((newKey) => {
          if (!Object.keys(response[0])?.includes(newKey)) {
           response[0][newKey] = null; 
          }
        });
        setStackChartData(response)
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    SprintDetailHandler();
  }, [project, epicData, selectedViewBy, viewType, epicData, date]);

  useEffect(() => {
    issueDetailsHandler();
  }, [issueType, date, viewType, epicData, issueLevelCost]);

  useEffect(() => {
    if (iteration) {
      staclchartHandler();
    }
  }, [iteration, date, graphType, viewType, epicData]);

  // Header Cost
  const handleConst = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCostClose = () => {
    setAnchorEl(null);
  };

  const ApplyHandler = () => {
    setSelectedViewBy(`Cost${Math.random()}`);
    handleCostClose();
  };

  // Issue level Cost
  const handleIssueConst = (event) => {
    setIssueCostHandler(event.currentTarget);
  };

  const handleIssueClose = () => {
    setIssueCostHandler(null);
  };

  const ApplyIssueHandler = () => {
    setIssueLevelCost(issueCostAmt);
    handleIssueClose();
  };

  const epicDropDownHandler = (url, list) => {
    setEpicData({ url: url.trim(), selectedEpic: list });
  };

  useEffect(() => {
    switch (viewType) {
      case "Percent":
        setTrendUnit("%");
        setTrendLabel("Time (%)");
        break;
      case "FTE":
        setTrendUnit("FTE");
        setTrendLabel("FTE");
        break;
      case "Cost":
        setTrendUnit("$");
        setTrendLabel("Cost ($)");
        break;
      default:
        break;
    }
  }, [viewType]);

  return (
    <>
      <Box mb={2}>
        <InvestmentFilter
          setDate={setDate}
          date={date}
          issueType={issueType}
          setIssueType={setIssueType}
          anchorEl={anchorEl}
          handleClose={handleCostClose}
          handleClick={handleConst}
          ApplyHandler={ApplyHandler}
          costAmt={costAmt}
          setCostAmt={setCostAmt}
          epicDropDownHandler={epicDropDownHandler}
          selectedViewBy={viewType}
          setSelectedViewBy={setSelectedViewBy}
          HeaderData={HeaderData}
          projectId={project}
        />
      </Box>
      <Grid container spacing={2} style={{ marginBottom: "20px" }}>
        <Grid item xs={3}>
          <Card className={`customCard`}>
            <CardDetail
              img={check}
              value={`${HeaderData?.TaskCount || ""}`}
              name={`No. of ${config.type} completed`}
            />
          </Card>
        </Grid>
      </Grid>
      <InvestmentProfileTemplate
        setIssueType={setIssueType}
        issueList={issueList}
        pieGraphData={pieGraphData}
        issueData={issueData}
        issueColumn={issueColumn}
        issueType={issueType}
        // date={date} for hide date from issue level
        setDate={setDate}
        StackedColumnData={stackChartData}
        StackedColumnFlag={true}
        iteration={iteration}
        setIteration={setIteration}
        setTrendDate={setTrendDate}
        // trendDate={trendDate} for hide date from trend
        setGraphType={setGraphType}
        graphType={graphType}
        TrendUnit={TrendUnit}
        trendLabel={TrendLabel}
        isCumulative={Cumulative}
        showCost={handleIssueConst}
        issueCostHandler={issueCostHandler}
        handleIssueCostClose={handleIssueClose}
        setIssueCostAmt={setIssueCostAmt}
        issueCostAmt={issueCostAmt}
        ApplyIssueHandler={ApplyIssueHandler}
        totalCostInDoller={totalCostInDoller}
      />
    </>
  );
};

export default TeamInvestmentProfile;
