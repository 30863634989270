import { Box, Card, Tab, Tabs } from "@mui/material";
import React from "react";
import CardSection from "./cardSection";
import BenchMarkTable from "./benchMarkTable";

const CardAndTable = ({ benchmark }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // All Tab Component
  const getComponent = (item) => {
    switch (item) {
      case 0:
        return <CardSection cardItems={benchmark} />;
      case 1:
        return <BenchMarkTable tableData={benchmark} />;
      default:
        break;
    }
  };

  return (
    <div>
      <Card>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Card" />
              <Tab label="Table" />
            </Tabs>
          </Box>
        </Box>
        {getComponent(value)}
      </Card>
    </div>
  );
};

export default CardAndTable;
