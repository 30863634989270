import React, { useState } from 'react'
import { Button } from '@material-ui/core'

import { Calendar } from 'react-date-range';
// import * as locales from 'react-date-range/dist/locale';
import { enUS } from 'react-date-range/dist/locale';
 
import SelectField from '../select/selectField';
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { format } from "date-fns";

function DatePicker({ value, setValue, placeHolder = "Select Date", className = "", style = {} }) {
    const locales = { enUS };
    const [openRange, setOpenRange] = useState(false)
    return (
        <>
            <Button
                onClick={() => setOpenRange(true)}
                style={{
                    border: `1px solid rgb(220, 220, 220)`,
                    width: "230px",
                    textTransform: "capitalize",
                    padding: "5px",
                    fontSize: "14px",
                    fontWeight: 400,
                    paddingLeft: "14px ",
                    paddingRight: "11px",
                    justifyContent: "space-between",
                    borderRadius: "5px",
                    height: "54px",
                    ...style
                }}
                className={className}
                endIcon={<DateRangeOutlinedIcon />}
            >
                {value ? format(value,
                    "dd-MMM-yyyy"
                ) : placeHolder}
            </Button>
            <Dialog
                maxWidth="sm"
                open={openRange}
                onClose={() => setOpenRange(false)}
            >
                <DialogContent style={{ overflow: "hidden" }}>
                    <Calendar onChange={item => {
                        setOpenRange(false)
                        setValue(item)
                    }}
                        months={2}
                        direction="horizontal"
                        minDate={new Date()}
                        locale={locales.enUS} // Correctly reference the locale
                        // locale={locales['English (United States)']} 
                        date={value} />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default DatePicker