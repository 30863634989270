import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Communication from "../../../../components/communication";
import instance from "../../../../utils/axiosHelper";
import CardDetail from "../../../../components/Header/cardDetail";
import { Box, Button, Card, Grid } from "@material-ui/core";
import involment from "../../../../assets/involment.png";
import { datePayload, getSelectedDate } from "../../../../utils/moduleTabApi";
import CommonTable from "../../../../components/commonTable";
import EpicDropDown from "../../../../components/epicDropDown";
import SelectField from "../../../../components/select/selectField";
import RangeSelector from "../../../../components/rangeSelector";
import { GlobalData } from "../../../../context/globalData";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";

function CommunicationPage() {
  const { projectRangePickerValue } = useContext(GlobalData);
  const { projectId } = useParams();
  const [iteration, setIteration] = useState(30);
  const [data, setData] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [date, setDate] = useState(projectRangePickerValue);
  const [openRange, setOpenRange] = useState(false);
  const [epicData, setEpicData] = useState({ url: "", selectedEpic: [] });

  const epicDropDownHandler = (url, list) => {
    setEpicData({ url: url?.trim(), selectedEpic: list });
  };

  const columns = [
    {
      title: "Task Id",
      field: "TaskId",
      headerStyle: {
        textAlign: "left",
        backgroundColor: "#efefef",
      },
      render: (rowData) => (
        <a target="_blank" href={rowData.TaskLink} rel="noreferrer">
          {rowData.TaskId}
        </a>
      ),
    },
    {
      title: "Assignee",
      field: "Assignee",
      headerStyle: {
        textAlign: "left",
        backgroundColor: "#efefef",
      },
    },
    {
      title: "Team Name",
      field: "TeamName",
      headerStyle: {
        textAlign: "left",
        backgroundColor: "#efefef",
      },
    },
    {
      title: "Pull Requests Count",
      field: "PullRequestsCount",
      headerStyle: {
        textAlign: "left",
        backgroundColor: "#efefef",
      },
    },
    {
      title: "Commits Count",
      field: "CommitsCount",
      headerStyle: {
        textAlign: "left",
        backgroundColor: "#efefef",
      },
    },
  ];

  const getList = () => {
    const dateUrl = datePayload(date);
    let url;
    if (epicData.url) {
      url = `/api/v2/projects/involvementResource/${projectId}${epicData.url}&iteration=${iteration}&${dateUrl}`;
    } else {
      url = `/api/v2/projects/involvementResource/${projectId}?iteration=${iteration}&${dateUrl}`;
    }
    instance(url)
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getComplexity = () => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/cm/communications/complexity/${projectId}?${dateUrl}`;
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    instance(url)
      .then((res) => {
        setDataList(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getList();
    getComplexity();
  }, [epicData.url, iteration, date]);
  return (
    <>
      <Box display={"flex"}>
        <EpicDropDown epicDropDownHandler={epicDropDownHandler} projectId={projectId}/>
        <>
          {/* <div>
              <NormalSelect
                option={[
                  { label: "Monthly", value: 30 },
                  { label: "Biweekly", value: 14 },
                  { label: "Weekly", value: 7 },
                  // { label: "Daily", value: 1 },
                ]}
                value={iteration}
                handleChange={(value) => setIteration(value)}
              />
            </div> */}
          <div style={{ marginTop: -8 }}>
            <SelectField
              input={
                <Button
                  onClick={() => setOpenRange(true)}
                  style={{
                    border: "1px solid #b7b7b7",
                    width: "230px",
                    textTransform: "capitalize",
                    padding: "5px",
                    fontSize: "14px",
                    fontWeight: 400,
                    paddingLeft: "14px ",
                    paddingRight: "11px",
                    justifyContent: "space-between",
                    borderRadius: "5px",
                    height: "54px",
                  }}
                  endIcon={<DateRangeOutlinedIcon />}
                >
                  {getSelectedDate(date)}
                </Button>
              }
              onClick={() => setOpenRange(true)}
            />
            <RangeSelector
              open={openRange}
              setOpen={setOpenRange}
              pickerHandler={(val) => setDate(val)}
              value={date}
              isNextDate={false}
            />
          </div>
        </>
      </Box>
      <Grid container spacing={2} style={{ marginBottom: "20px" }}>
        <Grid item xs={3}>
          <Card className={`customCard`}>
            <CardDetail
              img={involment}
              value={`${data?.involvePer} %`}
              name={"Involvement"}
            />
          </Card>
        </Grid>
      </Grid>
      <Communication reviewer={data.reviewer} submitter={data.submitter} />
      <Card className={`customCard mt-5`}>
        <CommonTable
          data={dataList}
          columns={columns}
          options={{ paging: false, search: false }}
          title={
            "Top 5 Task with maximum complexity (based on Commit & Pull Request)"
          }
        />
      </Card>
    </>
  );
}

export default CommunicationPage;
