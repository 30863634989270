import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Popover,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import RangeDatePicker from "../RangeDatePicker";
import EpicDropDown from "../epicDropDown";
import NormalSelect from "../select/normalSelect";
import { config } from "../../Config/permission";
import CheckIcon from "@mui/icons-material/Check";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const InvestmentFilter = ({
  setDate,
  date,
  setCostAmt,
  costAmt,
  selectedViewBy,
  setSelectedViewBy,
  anchorEl,
  handleClose,
  handleClick,
  ApplyHandler,
  epicDropDownHandler,
  HeaderData,
  projectId
}) => {
  return (
    <Box display={"flex"} gridGap={10} alignItems={"start"}>
      <RangeDatePicker setDate={setDate} date={date} isNextDate={false} />

      {/* <Box width={"25%"} px={2}>
        <Box display={"flex"} alignItems={"center"} mx={2}>
          <div className="investmentProfileDates">
            <Tooltip title="Start Date" placement="top" arrow>
              <CheckIcon style={{ color: "rgb(100, 132, 247)" }} />
            </Tooltip>
          </div>
          <div className="midLine"></div>
          <div className="investmentProfileDates">
            <Tooltip title="Last Work" placement="top" arrow>
              <NavigateNextIcon style={{ color: "rgb(100, 132, 247)" }} />
            </Tooltip>
          </div>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <p>{HeaderData?.StartDate}</p>
          <p>{HeaderData?.EndDate}</p>
        </Box>
      </Box> */}
      <Box marginLeft={"10px"}>
        {epicDropDownHandler && (
          <EpicDropDown
            epicDropDownHandler={epicDropDownHandler}
            limitTags={1}
            width={400}
            style={{ width: 400, marginBottom: 0 }}
            projectId={projectId}
          />
        )}
      </Box>
      <ButtonGroup color="primary" size="large">
        <Button
          style={{ minHeight: 53 }}
          variant={selectedViewBy === "Percent" ? "contained" : "outlined"}
          onClick={() => setSelectedViewBy("Percent")}
        >
          %
        </Button>
        <Button
          style={{ minHeight: 53 }}
          variant={selectedViewBy === "FTE" ? "contained" : "outlined"}
          onClick={() => setSelectedViewBy("FTE")}
        >
          FTE
        </Button>
        <Button
          style={{ minHeight: 53 }}
          variant={selectedViewBy === "Cost" ? "contained" : "outlined"}
          onClick={handleClick}
        >
          Cost $
        </Button>
      </ButtonGroup>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card>
          <CardContent>
            <div>
              <label>
                Please put your fully loaded engineers cost per{" "}
                {config.type === "Task" ? "Week" : "Month"}
              </label>
              <br />
              <Box display={"flex"} alignItems={"center"} gridGap={2}>
                $
                <TextField
                  value={costAmt}
                  onChange={(e) => {
                    setCostAmt(e.target.value);
                  }}
                  style={{
                    width: "400px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </Box>
              <br />
              <Button
                disabled={costAmt ? false : true}
                onClick={() => {
                  ApplyHandler();
                }}
                variant="outlined"
                color="primary"
              >
                {" "}
                Apply
              </Button>
            </div>
          </CardContent>
        </Card>
      </Popover>
    </Box>
  );
};

export default InvestmentFilter;
