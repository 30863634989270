import React, { useContext, useEffect, useMemo, useState } from "react";
import { GlobalData } from "../../../../context/globalData";
import instance from "../../../../utils/axiosHelper";
import {
  addLeadingZeroToDateRange,
  datePayload,
} from "../../../../utils/moduleTabApi";
import Filter from "../../../../components/effortAllocation/filter";
import StackedColumnChart from "../../../../components/stackedColumnChart/StackedColumnChart";
import CommonTable from "../../../../components/commonTable";
import DetailPopup from "../../../../components/effortAllocation/detailPopup";
import { getTableData } from "../../../Project/projectDashboard/effortAllocation/config/columnName";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom";
import { config } from "../../../../Config/permission";

function EffortAllocation() {
  const {projectId} = useParams()
  const { projectRangePickerValue } = useContext(GlobalData);
  const [date, setDate] = useState(projectRangePickerValue);
  const [iteration, setIteration] = useState(config.type === "Task" ? 14 : 30);
  const [issueType, setIssueType] = useState("Task");
  const [selectedViewBy, setSelectedViewBy] = useState("Allocation");
  const [tag, setTag] = useState("Bug");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [costAmt, setCostAmt] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowCost, setSelectedRowCost] = useState(null);
  const [popupData, setPopupData] = useState(null);
  const [epicData, setEpicData] = useState({ url: "", selectedEpic: [] });
  const { state } = useLocation();

  const getData = (row) => {
    let url = `/api/v2/projects/allocationsdata/trends?startDay=${row.category}&cost=${selectedRowCost}&tag=${tag}&issue=${row.issue}&resourceName=${state?.resourceName}`;
    if (epicData.url) {
      url += epicData.url.replace("?", "&");
    }
    instance
      .get(url)
      .then((res) => {
        const response = res.data[0];
        const data = [
          {
            value: `${response.FTE} FTE's`,
          },
          {
            value: `${response.AllocationHrs} Hrs`,
          },
          {
            value: `$ ${response.Cost}`,
          },
          {
            value: `${response.IssueCount} Issue`,
          },
        ];

        setPopupData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const openDrawerHandler = (row, category) => {
    setSelectedRow({ ...row, category });
    setSelectedRowCost(null);
    setPopupData(null);
    setIsDrawerOpen(true);
  };
  const handleConst = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCostClose = () => {
    setAnchorEl(null);
  };

  const ApplyHandler = () => {
    setSelectedViewBy(`Cost${Math.random()}`);
    handleCostClose();
  };
  const viewType = useMemo(() => {
    const type = selectedViewBy.includes("Cost") ? "Cost" : selectedViewBy;
    return type;
  }, [selectedViewBy]);
  const graphHandler = () => {
    const dateUrl = datePayload(date);
    const leadingZero = addLeadingZeroToDateRange(dateUrl);
    let url = `/api/v2/projects/allocationsdata?issueType=${issueType}&${leadingZero}&tag=${tag}&type=${viewType}&resourceName=${state?.resourceName}`;
    if (viewType === "Cost") {
      url += `&cost=${costAmt}`;
    }
    if (epicData.url) {
      url += epicData.url.replace("?", "&");
    }
    instance
      .get(url)
      .then((res) => {
        setGraphData(res.data);
        const data = getTableData(
          openDrawerHandler,
          res.data,
          yAxesLabels[viewType]
        );
        setColumns(data.columns);
        setTableData(data.tableData);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    graphHandler();
  }, [date, tag, iteration, viewType, epicData]);

  const yAxesLabels = {
    FTE: "",
    Cost: "$",
    Allocation: " Hrs",
  };

  const epicDropDownHandler = (url, list) => {
    setEpicData({ url: url.trim(), selectedEpic: list });
  };
  const [filterTagOption, setFilterTagOption] = useState([]);
  useEffect(() => {
    const url = `/api/v2/issueTypeTag`;
    instance
      .get(url)
      .then((response) => {
        setFilterTagOption(response?.data?.IssueTypeTags);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);

  return (
    <>
      <Filter
        setDate={setDate}
        date={date}
        issueType={issueType}
        setIssueType={setIssueType}
        iteration={iteration}
        setIteration={setIteration}
        selectedViewBy={viewType}
        setSelectedViewBy={setSelectedViewBy}
        anchorEl={anchorEl}
        handleClose={handleCostClose}
        handleClick={handleConst}
        ApplyHandler={ApplyHandler}
        tag={tag}
        setTag={setTag}
        costAmt={costAmt}
        setCostAmt={setCostAmt}
        epicDropDownHandler={epicDropDownHandler}
        filterTagOption={filterTagOption}
        projectId={projectId}
      />
      <StackedColumnChart
        graphData={graphData}
        yAxesLabel={`${viewType} ${yAxesLabels[viewType]}`}
        unit={yAxesLabels[viewType]}
      />
      <CommonTable
        columns={columns}
        data={tableData}
        options={{ sorting: true }}
      />

      <DetailPopup
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        popupData={popupData}
        selectedRow={selectedRow}
        getData={getData}
        setSelectedRowCost={setSelectedRowCost}
        selectedRowCost={selectedRowCost}
      />
    </>
  );
}

export default EffortAllocation;
