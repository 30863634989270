import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Alert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";

function AlertNotification({ anchorEl, handleClose }) {
  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <div className="ps-3">Alerts</div>
      <MenuItem style={{ minWidth: "300px" }} onClick={handleClose}>
        <Alert severity="error" style={{ width: "100%" }}>
          <p style={{ marginBottom: "3px" }}>Vikas is Burn out</p>
          <Link to="/resourceBurnOut">View More</Link>
        </Alert>
      </MenuItem>
      <MenuItem style={{ minWidth: "300px" }} onClick={handleClose}>
        <Alert severity="error" style={{ width: "100%" }}>
          <p style={{ marginBottom: "3px" }}>
            {" "}
            Ruhikesh is underutilized / Disengaged{" "}
          </p>
          <Link to="/ResourceUnderutilized">View More</Link>
        </Alert>
      </MenuItem>
      {/* <MenuItem style={{ minWidth: "300px" }} onClick={handleClose}>
        <Alert severity="error" style={{ width: "100%" }}>
          <p style={{ marginBottom: "3px" }}> Ajay has process isuue</p>
          <Link to="/process">View More</Link>
        </Alert>
      </MenuItem> */}
    </Menu>
  );
}

export default AlertNotification;
