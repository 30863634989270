import SingleModule from "../../Module/SingleModule";
import ModulesPage from "../../Project/modules";
import SingleProject from "../../Project/SingleProject";
import ProjectPage from "../../Project/project";
// import ProjectDashboard from "../../Project/projectDashboard/projectDashboard";
import Resources from "../../Resource/ResourceList/resources";
import Teams from "../../Teams";
import SingleResource from "../../Resource/SingleResource";
import { Jira } from "../../../components/jira";
import Setting from "../../../hoc/layout/setting";
import InvestmentProfile from "../../investmentProfile/InvestmentProfile";
import GroupForm from "../../settingPages/metric/group/groupForms";
import MetricLevelForm from "../../settingPages/metric/matricLevelRisk/matricLevelForm";
import MetricForm from "../../settingPages/metric/metric/metricForm";
import SingleResoureceMetricInfo from "../../Resource/singleResource/SingleResoureceMetricInfo";
import AlertFormWorkForm from "../../settingPages/alertFrameWork/alertFormWorkForm";
import ResourceBurnOut from "../../AlertScreen/reourceHelth/resourceBurnOut";
import ResourceUtilized from "../../AlertScreen/reourceHelth/resourceUtilized";
// import Process from "../../AlertScreen/process/process";
// import ProjectExecution from "../../AlertScreen/projectExecution/projectExecution";
// import Quality from "../../AlertScreen/quality/quality";
// import Productivity from "../../AlertScreen/productivity/productivity";
// import TeamMorale from "../../AlertScreen/teamMorale/teamMorale";
// import InterteamCommunication from "../../AlertScreen/interteamCommunication/interteamCommunication";
// import Space from "../../AlertScreen/space/space";
import Sprint from "../../Sprint";
import SprintDashboard from "../../Sprint/Detail";
import ProjectDetail from "../../Project/projectDashboard";
import CommunicationPage from "../../Project/projectDashboard/communication";
import ReportForm from "../../settingPages/reports/reportForm";
import ProjectForm from "../../settingPages/metric/Project/form";
import ScenarioPlanner from "../../Project/projectDashboard/health/execution/scenarioPlanning";
import WorkAllocation from "../../workAllocation";
import AlertFromRisks from "../../settingPages/alertFrameWork/AlertFromRisks";

const securedRoutes = [
  /* ------------------ Project routes ------------------- */
  {
    path: "/project",
    Component: <ProjectPage />,
  },
  {
    path: "/project/:projectId",
    Component: <ProjectDetail />,
  },
  {
    path: "/project/:projectId/kpi/:matrixId",
    Component: <SingleProject />,
  },
  {
    path: "/project/communication/:projectId",
    Component: <CommunicationPage />,
  },
  {
    path: "/project/:projectId/teams",
    Component: <Teams />,
  },
  {
    path: "/project/:projectId/teams/resources",
    Component: <Resources />,
  },
  {
    path: "/project/:projectId/resource/:resourceId",
    // path: "/project/resource/:projectId/resourceId/:resourceKpiId",
    Component: <SingleResource />,
  },
  {
    // Currently not in used , only 1 place were used (spi chart project bt not working bz work done is not completed)
    path: "/project/resource/:projectId",
    Component: <SingleResource />,
  },
  {
    path: "/project/:projectId/single-resource/:resourceId",
    Component: <SingleResoureceMetricInfo />,
  },
  {
    path: "/project/:projectId/healthExecution/:epicId",
    Component: <ScenarioPlanner />,
  },
  /* ------------------ Modules routes ------------------- */
  {
    path: "/modules",
    Component: <ModulesPage />,
  },
  {
    path: "/modules/:moduleId",
    Component: <SingleModule />,
  },
  /* ------------------ Sprint routes ------------------- */
  {
    path: "/sprint1",
    Component: <Jira />,
  },
  {
    path: "/sprint",
    Component: <Sprint />,
  },
  {
    path: "/sprint/:sprintId",
    Component: <SprintDashboard />,
  },
  /* ------------------ Investment profile routes ------------------- */
  {
    path: "/investment-profile",
    Component: <InvestmentProfile />,
  },
  /* ------------------ Notification routes ------------------- */
  {
    path: "/notification",
    Component: <h1>Notifications Page</h1>,
  },
  /* ------------------ Settings routes ------------------- */
  {
    path: "/settings/:settingTab",
    Component: <Setting />,
  },
  {
    path: "/settings/:settingTab/form/group",
    Component: <GroupForm />,
  },
  {
    path: "/settings/:settingTab/form/metricLevel/:id",
    Component: <MetricLevelForm />,
  },
  {
    path: "/settings/:settingTab/form/metric",
    Component: <MetricForm />,
  },

  {
    path: "/settings/:settingTab/form/report",
    Component: <ReportForm />,
  },
  {
    path: "/settings/:settingTab/form/project",
    Component: <ProjectForm />,
  },
  {
    path: "/resourceBurnOut",
    Component: <ResourceBurnOut />,
  },
  {
    path: "/ResourceUnderutilized",
    Component: <ResourceUtilized />,
  },
  /* ------------------ Resource Allocation routes ------------------- */
  {
    path: "/resourceAllocation",
    Component: <WorkAllocation />,
  },
  /* ------------------ Alert Framework routes ------------------- */
  {
    path: "/alert/alertFramework",
    Component: <AlertFromRisks />,
  },
  {
    path: "/alert/alertFramework/addAlert",
    Component: <AlertFormWorkForm />,
  },
  // {
  //   path: "/process",
  //   Component: <Process />,
  // },
  // {
  //   path: "/projectExecution",
  //   Component: <ProjectExecution />,
  // },
  // {
  //   path: "/quality",
  //   Component: <Quality />,
  // },
  // {
  //   path: "/productivity",
  //   Component: <Productivity />,
  // },
  // {
  //   path: "/teamMorale",
  //   Component: <TeamMorale />,
  // },
  // {
  //   path: "/interteamCommunication",
  //   Component: <InterteamCommunication />,
  // },
  // {
  //   path: "/space",
  //   Component: <Space />,
  // },
];

export { securedRoutes };
