import React, { useEffect, useState } from "react";
import { Autocomplete, Checkbox, TextField, ListItemText } from "@mui/material";
import instance from "../../utils/axiosHelper";

const EpicDropDown = ({
  epicDropDownHandler,
  width = 500,
  style = {},
  limitTags = 2,
  projectId
}) => {
  const [selectedEpic, setSelectedEpic] = useState([]);
  const [epicList, setEpicList] = useState([]);

  // Add "Select All" as the first item in the list
  const enhancedEpicList = [
    { key: "all", epic: { name: "Select All" } },
    ...epicList,
  ];

  const handleSelect = (event, value) => {
    const isSelectAll = value.some((v) => v.epic?.name === "Select All");

    if (isSelectAll) {
      // If "Select All" is checked, either select all or clear all depending on current state
      if (selectedEpic.length === epicList.length) {
        setSelectedEpic([]);
        epicDropDownHandler("", []); // Pass an empty array when deselecting all
      } else {
        setSelectedEpic(epicList); // Select all epics except "Select All"
        epicDropDownHandler("?epic=all", epicList);
      }
    } else {
      // Handle individual selections
      let url = value.reduce((acc, e, index) => {
        return index === 0 ? `?epic=${e.key}` : `${acc}&epic=${e.key}`;
      }, "");

      epicDropDownHandler(url.trim(), value);
      setSelectedEpic(value);
    }
  };

  useEffect(() => {
    instance
      .get(`/api/v2/projects/epic/list?projectIds=${projectId}`)
      .then((res) => {
        if (res.data) {
          setEpicList(res.data);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, [projectId]);

  return (
    <div style={{ width: 500, marginBottom: "20px", ...style }}>
      <Autocomplete
        multiple
        limitTags={limitTags}
        style={{ width: width, paddingRight: `10px`, borderRadius: 10 }}
        id="tags-readOnly"
        value={selectedEpic}
        onChange={handleSelect}
        options={enhancedEpicList}
        getOptionLabel={(option) => option?.epic?.name}
        renderInput={(params) => (
          <TextField {...params} placeholder="Select Epic" />
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              checked={
                option.epic.name === "Select All"
                  ? selectedEpic.length === epicList.length
                  : selectedEpic.some((epic) => epic.key === option.key)
              }
              indeterminate={
                option.epic.name === "Select All" &&
                selectedEpic.length > 0 &&
                selectedEpic.length < epicList.length
              }
            />
            <ListItemText primary={option.epic.name} />
          </li>
        )}
      />
    </div>
  );
};

export default EpicDropDown;

// ======================= Old Code =====================================

// import React, { useEffect, useState } from "react";
// import instance from "../../utils/axiosHelper";
// import { Autocomplete, Checkbox, ListItemText, TextField } from "@mui/material";

// function EpicDropDown({
//   epicDropDownHandler,
//   width = 500,
//   style = {},
//   limitTags = 2,
// }) {
//   const [selectedEpic, setSelctedEpic] = useState([]);
//   const [EipicList, setEplicList] = useState([]);
//   const handleSelect = (value) => {
//     let url = " ";
//     const isSelectAll = value.some((v) => v.epic?.name === "All Epics");

//     if (isSelectAll) {
//       setSelctedEpic(EipicList);
//       value = EipicList;
//     }
//     if (isSelectAll) {
//       // If "All" is selected, set URL for "all"
//       url = "?epic=all";
//       epicDropDownHandler(url.trim(), EipicList); // Pass all epics when "All Epics" is selected
//     } else {
//       // Handle individual epics selection
//       if (value?.length > 0) {
//         value.forEach((e, index) => {
//           if (index === 0) {
//             url += `?epic=${e.key}`;
//           } else {
//             url += `&epic=${e.key}`;
//           }
//         });
//         epicDropDownHandler(url.trim(), value);
//       } else {
//         epicDropDownHandler("", value);
//       }
//     }

//     setSelctedEpic(value);
//   };
//   const getEpicList = () => {
//     instance
//       .get(`/api/v2/projects/epic/list`)
//       .then((res) => {
//         if (res.data) {
//           setEplicList([
//             { key: "all", id: 232, epic: { name: "All Epics" } },
//             ...res.data,
//           ]);
//         }
//       })
//       .catch((err) => {
//         console.log("error", err);
//       });
//   };
//   useEffect(() => {
//     getEpicList();
//   }, []);
//   return (
//     <div style={{ width: 500, marginBottom: "20px", ...style }}>
//       <Autocomplete
//         multiple
//         limitTags={limitTags}
//         style={{
//           width: width,
//           // border: "1px solid",
//           paddingRight: `10px`,
//           borderRadius: 10,
//         }}
//         id="tags-readOnly"
//         value={selectedEpic}
//         onChange={(e, value) => handleSelect(value)}
//         options={EipicList}
//         getOptionLabel={(option) => option?.epic?.name}
//         renderInput={(params) => (
//           <TextField {...params} placeholder="Select Epic" />
//         )}
//         renderOption={(props, option, { selected }) => (
//           <li {...props}>
//             <Checkbox checked={selected} />
//             <ListItemText primary={option.epic.name} />
//           </li>
//         )}
//       />
//     </div>
//   );
// }

// export default EpicDropDown;
