import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const YearlyLineChart = ({ data,yAxisLabel }) => {
  useEffect(() => {
    am4core.useTheme(am4themes_animated);

    // Create chart instance
    let chart = am4core.create("yearlyLineChart", am4charts.XYChart);
    chart.colors.step = 2;

    // Ensure all required fields exist in data
    const normalizedData = data.map(item => ({
      sprintName: item.sprintName || "",   // Ensure sprintName exists
      val: item.val !== undefined ? item.val : null,   // Handle missing val with null
      val2: item.val2 !== undefined ? item.val2 : null // Handle missing val2 with null
    }));

    chart.data = data;

    // Create X-axis (Category axis for strings like sprint names)
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "sprintName";  // Map the sprintName field to the category axis
    categoryAxis.renderer.minGridDistance = 50;
    categoryAxis.title.text = "Sprint Names";  // Optional: label for the X-axis
    categoryAxis.renderer.labels.template.rotation = 360;  // Rotate labels if needed

    // Create a common Y-axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = yAxisLabel;  // Add the label text here
    valueAxis.title.fontSize = 18;  // Optional: adjust the font size

    // Create series function
    function createSeries(field, name) {
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "sprintName";  // Use categoryX for the X-axis field
      series.name = name;
      series.tooltipText = "{name}: [bold]{valueY}[/]";
      series.strokeWidth = 2;
      series.tensionX = 0.8;
      series.showOnInit = true;

      let interfaceColors = new am4core.InterfaceColorSet();
      let bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.stroke = interfaceColors.getFor("background");
      bullet.circle.strokeWidth = 2;
    }

    // Dynamically create series for each field in the data
    const dataFields = Object.keys(chart.data[0]).filter(
      (key) => key !== "sprintName"
    );
    dataFields.forEach((field) => {
      createSeries(field, field);
    });

    // Add and customize the legend
    chart.legend = new am4charts.Legend();

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    // Cleanup function to dispose of chart instance when component unmounts
    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [data]);

  return <div id="yearlyLineChart" style={{ width: "100%", height: "800px" }} />;
};

export default YearlyLineChart;
