export const levelMap = {
  PROJECT: "ProjectLevel",
  TEAM: "TeamLevel",
  RESOURCE: "TeamMemberLevel",
  MODULE: "ModuleLevel",
  SPRINT: "SprintLevel",
};

export const riskMap = {
  1: "Low",
  2: "Medium",
  3: "High",
};
export const matricRisk = {
  1: "Ideal Range",
  2: "Under Commit",
  3: "Potential Over Commit",
  4: "Potential Under Commit",
};

export const riskMap2 = {
  1: "Good",
  2: "Average",
  3: "Bad",
};

export const RiskCategoryMap = {
  "Green - Low Risk": "Low",
  "Yellow - Medium Risk": "Medium",
  "Red - High Risk": "High",
};
export const RiskCatMap = {
  Low: "Green - Low Risk",
  Medium: "Yellow - Medium Risk",
  High: "Red - High Risk",
};
export const riskLevelMap = {
  1: "Green - Low Risk",
  2: "Yellow - Medium Risk",
  3: "Red - High Risk",
};
export const riskLevel = [
  { lable: "Green - Low Risk", value: 1 },
  { lable: "Yellow - Medium Risk", value: 2 },
  { lable: "Red - High Risk", value: 3 },
];
export const capacityAccuracy = [
  { lable: "Green - Ideal Range", value: 1 },
  { lable: "Blue - Under Commit", value: 2 },
  { lable: "Red - Potential Over Commit", value: 3 },
  { lable: "Orange - Potential Under Commit", value: 4 },
];

export const RiskValueMap = {
  "Green - Low Risk": 1,
  "Yellow - Medium Risk": 2,
  "Red - High Risk": 3,
};

export const SprintMap = {
  "Completed Sprints": "closed",
  "Ongoing Sprints": "active",
};

export const TimeLine = {
  Open: "magenta",
  "In Progress": "brown",
  "Dev Complete": "pink",
  "Code Reviewed": "blue",
  "In QA": "purple",
  "QA Verified": "orange",
  Released: "green",
  Closed: "red",
  name:"blue"
};

export const iterationMap = {
  7: "Weekly",
  1: "Daily",
  14: "Biweekly",
  30: "Monthly",
  90: "Quarterly",
};
export const level = {
  1: "Project Level",
  2: "Resources Level",
  3: "Team Level",
  4: "Module Level",
};
