import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import InfoIcon from "@material-ui/icons/Info";
import { config } from "../../Config/permission";

//create  row data
export const createData = (result) => {
  const data = [];
  result?.forEach((e) => {
    const columnData = {
      SprintName: e.SprintName,
      ProjectName: e.ProjectName,
      Id: e.Id,
      State: e.State,
    };

    // Add metric names to the columnData object
    e?.metrcis?.forEach((item) => {
      columnData[item.DisplayName] = {
        name: item.DisplayName,
        value: item.Value,
        id: item.MetricId,
      };
    });
    data.push(columnData);
  });
  return data;
};

export const generateColumns = (
  resourceData,
  viewMore,
  definationPopupHandler
) => {
  const excludedItems = [
    "SprintName",
    "ProjectName",
    "tableData",
    "Id",
    "State",
  ];
  const columns = [
    {
      field: "SprintName",
      title: "Sprint Name",
      // customFilterAndSearch: (term, rowData) => {
      //   return (
      //     rowData.items.findIndex((item) => item.name.includes(term)) != -1
      //   );
      // },
    },
    {
      field: "ProjectName",
      title: "Project Name",
      searchable: true,
    },
  ];

  resourceData?.length > 0 &&
    Object.keys(resourceData[0])?.forEach((item) => {
      if (
        item !== "SprintName" &&
        item !== "ProjectName" &&
        item !== "tableData" &&
        item !== "Id" &&
        item !== "State"
      ) {
        columns.push({
          title: (
            <HeaderTitle
              lablel={item}
              definationPopupHandler={definationPopupHandler}
            />
          ),
          field: item,
          searchable: true,
          render: (data) => {
            return data[item]?.value?.toFixed();
          },
        });
      }
    });
  columns.push(
    {
      title: "Status",
      field: "State",
    },
    {
      title: "Action",
      field: "Action",
      render: (row) => {
        return (
          <div
            onClick={() => {
              viewMore(row);
            }}
            style={{ color: "#5EBDCC", cursor: "pointer" }}
          >
            <span>View More &nbsp;</span>
            <InfoIcon style={{ color: "gray" }} />
          </div>
        );
      },
    }
  );
  return columns;
};

export const iconColors = ["skyblue", "red", "orange", "green", "blue"];
export const icons = [
  "fa fa-archive",
  "fas fa-exclamation-triangle",
  "fa fa-clock-o",
  "fa fa-check-circle",
  "fa fa-rotate-left",
  "fa fa-info",
  // "fa fa-info",
  // "fa fa-info",
  // "fa fa-info",
];

const HeaderTitle = ({ lablel, definationPopupHandler }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <div>{lablel} </div>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => definationPopupHandler(lablel)}
      >
        <InfoIcon style={{ color: "rgb(203 196 196)" }} fontSize="small" />
      </div>
    </div>
  );
};
