import React, { useContext, useEffect, useState } from "react";
import { Box, Card, CardContent, Grid } from "@material-ui/core";
import { iconColors, icons } from "../data";
import document from "../../../assets/document.png";
import users from "../../../assets/users.png";
import { format } from "date-fns";
import InfoIcon from "@material-ui/icons/Info";
import { GlobalData } from "../../../context/globalData";
import { config } from "../../../Config/permission";

function HeaderCard({
  classes,
  headerChips,
  getMetricData,
  teamModelHander,
  setIsSprintSpillover,
  sprintSpelloverData,
  sprintBarGraphData,
}) {
  const { setIsDefinaionPopup, setDefinationKPI, setTotalWorkCommit } =
    useContext(GlobalData);

  const openKpi = (kpi) => {
    // e.stoppropagation()
    setIsDefinaionPopup(true);
    setDefinationKPI(kpi);
  };

  useEffect(() => {
    setTotalWorkCommit(headerChips?.["Work Commitment"]);
  }, [headerChips]);

  const [viewAll, setViewAll] = useState(false);

  return (
    <>
      <div className={classes.mainhead}>
        <div>
          <label>State : </label>
          <span className="mx-2">{sprintBarGraphData?.State}</span>
        </div>
        <div>
          <b>Start Date : </b>
          {sprintBarGraphData?.startDate && (
            <label>
              {format(new Date(sprintBarGraphData?.startDate), "dd-MMM-yyy")}
            </label>
          )}
        </div>
        <div>
          <b>End Date : </b>{" "}
          {sprintBarGraphData?.endDate && (
            <label>
              {format(new Date(sprintBarGraphData?.endDate), "dd-MMM-yyy")}
            </label>
          )}
        </div>
        <div>
          <label>Project Name : </label>
          <span className="mx-2">
            {sprintBarGraphData?.project?.displayName}
          </span>
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Grid container spacing={2}>
            {Object.keys(headerChips).map((item, index) =>
              viewAll ? (
                <>
                  {console.log(item, "item")}
                  {/* This condition for if sprint is close and item === Story
                      Point In Progress */}
                  {sprintSpelloverData?.State === "closed" ? (
                    <>
                      {/* This condition for hide Story Point In Progress */}
                      {item !== "Story Point In Progress" &&
                        item !== "Sprint Completion ratio" &&
                        item !== "Story Point" &&
                        item !== "Story Point Assigned" &&
                        item !== "Story Point Done" &&
                        item !== "Story Point Done In Time" &&
                        item !== "Story Point In Progress" &&
                        item !== "Story Point On Hold" &&
                        item !== "Story Point2" &&
                        item !== "Work Commitment2" && (
                          <Grid item xs={4} key={index}>
                            <Card className={`${classes.card} customCard`}>
                              <CardContent>
                                <div className={classes.cardContaner}>
                                  <div className={classes.icon}>
                                    <i
                                      className={icons[index]}
                                      style={{
                                        fontSize: "20px",
                                        color: iconColors[index],
                                      }}
                                    ></i>
                                  </div>
                                  <div className={classes.textContianer}>
                                    <h3>
                                      {headerChips[item][0]?.Value?.toFixed()}{" "}
                                      {item == "Hygine Score" && " % "}
                                      {(item == "Avg Defect Lead Time" ||
                                        item == "Avg Lead Time") &&
                                        " Days"}
                                    </h3>
                                    <h6>
                                      {item}{" "}
                                      <span onClick={() => openKpi(item)}>
                                        {item !== "Story Point Done" &&
                                          item !== "Story Point Done In Time" &&
                                          item !== "Story Point In Progress" &&
                                          item !== "Story Point On Hold" && (
                                            <InfoIcon
                                              style={{ color: "gray" }}
                                            />
                                          )}
                                      </span>
                                      <br />
                                      {item !== "Story Point Assigned" &&
                                        item !== "Story Point Done" &&
                                        item !== "Story Point Done In Time" &&
                                        item !== "Story Point In Progress" &&
                                        item !== "Story Point On Hold" && (
                                          <span
                                            className={classes.ViewMore}
                                            onClick={() =>
                                              getMetricData(
                                                item,
                                                headerChips[item]
                                              )
                                            }
                                          >
                                            View more{" "}
                                          </span>
                                        )}
                                    </h6>
                                  </div>
                                </div>
                              </CardContent>
                            </Card>
                          </Grid>
                        )}
                    </>
                  ) : (
                    <>
                      {item !== "Sprint Completion ratio" &&
                        item !== "Story Point" &&
                        item !== "Story Point Assigned" &&
                        item !== "Story Point Done" &&
                        item !== "Story Point Done In Time" &&
                        item !== "Story Point In Progress" &&
                        item !== "Story Point On Hold" &&
                        item !== "Story Point2" &&
                        item !== "Work Commitment2" && (
                          <Grid item xs={4} key={index}>
                            <Card className={`${classes.card} customCard`}>
                              <CardContent>
                                <div className={classes.cardContaner}>
                                  <div className={classes.icon}>
                                    <i
                                      className={icons[index]}
                                      style={{
                                        fontSize: "20px",
                                        color: iconColors[index],
                                      }}
                                    ></i>
                                  </div>
                                  <div className={classes.textContianer}>
                                    <h3>
                                      {headerChips[item][0]?.Value?.toFixed()}{" "}
                                      {item == "Hygine Score" && " % "}
                                      {(item == "Avg Defect Lead Time" ||
                                        item == "Avg Lead Time") &&
                                        " Days"}
                                    </h3>
                                    <h6>
                                      {item}{" "}
                                      <span onClick={() => openKpi(item)}>
                                        {item !== "Story Point Done" &&
                                          item !== "Story Point Done In Time" &&
                                          item !== "Story Point In Progress" &&
                                          item !== "Story Point On Hold" && (
                                            <InfoIcon
                                              style={{ color: "gray" }}
                                            />
                                          )}
                                      </span>
                                      <br />
                                      {item !== "Story Point Assigned" &&
                                        item !== "Story Point Done" &&
                                        item !== "Story Point Done In Time" &&
                                        item !== "Story Point In Progress" &&
                                        item !== "Story Point On Hold" && (
                                          <span
                                            className={classes.ViewMore}
                                            onClick={() =>
                                              getMetricData(
                                                item,
                                                headerChips[item]
                                              )
                                            }
                                          >
                                            View more{" "}
                                          </span>
                                        )}
                                    </h6>
                                  </div>
                                </div>
                              </CardContent>
                            </Card>
                          </Grid>
                        )}
                    </>
                  )}
                </>
              ) : (
                index < 2 && (
                  <>
                    {item !== "Work Commitment2" && (
                      <Grid item xs={4} key={index}>
                        <Card
                          className={`${classes.card} customCard`}
                          style={{ cursor: "pointer" }}
                        >
                          <CardContent>
                            <div className={classes.cardContaner}>
                              <div className={classes.icon}>
                                <i
                                  className={icons[index]}
                                  style={{
                                    fontSize: "20px",
                                    color: iconColors[index],
                                  }}
                                ></i>
                              </div>
                              <div className={classes.textContianer}>
                                <h3>
                                  {headerChips[item][0]?.Value?.toFixed()}{" "}
                                  {item == "Hygine Score" && " % "}
                                  {(item == "Avg Defect Lead Time" ||
                                    item == "Avg Lead Time") &&
                                    " Hours "}
                                </h3>
                                <h6>
                                  {item}
                                  <span onClick={() => openKpi(item)}>
                                    <InfoIcon style={{ color: "gray" }} />
                                  </span>
                                  <br />
                                  {item !== "Story Point Assigned" &&
                                    item !== "Story Point Done" && (
                                      <span
                                        onClick={() =>
                                          getMetricData(item, headerChips[item])
                                        }
                                        className={classes.ViewMore}
                                      >
                                        View more{" "}
                                      </span>
                                    )}
                                </h6>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}
                  </>
                )
              )
            )}
            <Grid
              item
              xs={4}
              onClick={() => {
                sprintSpelloverData?.State === "closed" &&
                  setIsSprintSpillover(true);
              }}
            >
              <Card
                className={`${classes.card} customCard`}
                style={{ cursor: "pointer" }}
              >
                <CardContent>
                  <div className={classes.cardContaner}>
                    <div className={classes.icon}>
                      <i
                        className={"fa fa-info"}
                        style={{
                          fontSize: "20px",
                          color: "green",
                        }}
                      ></i>
                    </div>
                    <div className={classes.textContianer}>
                      {sprintSpelloverData?.State === "closed" ? (
                        <>
                          <h3>
                            {headerChips?.["Story Point Assigned"]?.[0]?.Value -
                              headerChips?.["Story Point Done"]?.[0]?.Value}
                            <span>
                              (
                              {(
                                100 -
                                sprintSpelloverData["Sprint Completion ratio"]
                                  .value
                              ).toFixed()}
                              %)
                            </span>
                          </h3>
                          <h6>
                            {`${config.type} (Sprint Spillover)`}
                            <span onClick={() => openKpi("Sprint Spillover")}>
                              <InfoIcon style={{ color: "gray" }} />
                            </span>{" "}
                            <br />
                            <span className={classes.ViewMore}>View more </span>
                          </h6>
                        </>
                      ) : (
                        <div style={{ fontSize: "13px" }}>
                          Sprint Spillover will be calculated at the end of the
                          Sprint.
                        </div>
                      )}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Card className={"customCard"}>
            <CardContent>
              <div
                className={classes.sideCard}
                onClick={() => setViewAll(!viewAll)}
              >
                <img style={{ maxHeight: "21px" }} src={document} />
                <span> View all KPI</span>
              </div>
            </CardContent>
          </Card>
          {/* {sprintBarGraphData?.State !== "closed" && ( */}
          <Card className={"customCard"} style={{ marginTop: "10px" }}>
            <CardContent>
              <div
                className={classes.sideCard}
                onClick={() => teamModelHander()}
              >
                <img style={{ maxHeight: "21px" }} src={users} />
                <span>Developer Insight</span>
              </div>
            </CardContent>
          </Card>
          {/* )} */}
        </Grid>
      </Grid>
    </>
  );
}

export default HeaderCard;
