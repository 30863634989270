import MaterialTable from "material-table";
import React, { useState } from "react";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import Modal from "@material-ui/core/Modal";
import { tableIcons } from "../../utils/tableIcon";
import { Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EpicDropDown from "../epicDropDown";
import CheckSelect from "../select/CheckSelect";

function CommonTablePopup({
  open,
  setOpen,
  tableOption = {},
  columns,
  data = [],
  title,
  width = 600,
  setEpicData,
  componentList,
  selectedComponentData,
  setSelectedComponentData,
  resource,
  SelectedResource,
  setSelectedResource,
  allTeamMemberTeam,
  SelectedTeamMemberTeam,
  setSelectedTeamMemberTeam,
  projectId,
}) {
  const options = {
    sorting: false,
    search: false,
    toolbar: false,
    headerStyle: {
      backgroundColor: "#efefef",
    },
    pageSize: 10,
    paginationType: "stepped",
    ...tableOption,
  };
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid green",
      outline: "none",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1, 2, 2),
      top: "50%",
      left: "50%",
      transform: "translate(-50% ,-50%)",
      textAlign: "center",
      borderRadius: "20px",
      width: width,
    },
  }));
  const epicDropDownHandler = (url, list) => {
    setEpicData({ url: url?.trim(), selectedEpic: list });
  };
  const classes = useStyles();
  return (
    <Modal
      className="paginationStyling"
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.paper}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "12px",
            marginTop: "10px",
          }}
        >
          <div style={{ fontSize: "15px", fontWeight: "600" }}>{title}</div>
          <div>
            <HighlightOffOutlinedIcon
              style={{
                color: "#B1B1B1",
                cursor: "pointer",
              }}
              onClick={() => setOpen(!open)}
            />
          </div>
        </div>
        {componentList && (
          <div className="d-flex justify-content-between align-item-center">
            <div>
              <EpicDropDown
                epicDropDownHandler={epicDropDownHandler}
                projectId={projectId}
              />
            </div>
            <div>
              <CheckSelect
                names={resource}
                setValue={setSelectedResource}
                value={SelectedResource}
                placeholder="Select resource"
              />
            </div>
            <div>
              <CheckSelect
                names={allTeamMemberTeam}
                setValue={setSelectedTeamMemberTeam}
                value={SelectedTeamMemberTeam}
                placeholder="Select Team"
              />
            </div>
            <div>
              <CheckSelect
                names={componentList}
                setValue={setSelectedComponentData}
                value={selectedComponentData}
                placeholder="Select Component"
              />
            </div>
          </div>
        )}

        <MaterialTable
          style={{ boxShadow: "none" }}
          title="Android APP Module KPI'S"
          columns={columns}
          enableRowNumbers
          rowNumberMode="static"
          icons={tableIcons}
          data={data}
          options={options}
        />
      </div>
    </Modal>
  );
}

export default CommonTablePopup;
